export const useDegreeTypesStore = defineStore('degreeType', () => {
    const { $getApi } = useNuxtApp();

    const degreeTypes = ref([]);

    const getDegreeTypes = async () => {
        try {
            const res = await $getApi(`degree-type`);
            degreeTypes.value = res.data?.data || [];
        } catch {
            degreeTypes.value = [];
        }
    };

    return { degreeTypes, getDegreeTypes };
});

